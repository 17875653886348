import { navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { formatPageInformation } from "../../helpers/Meta"
import { getAppleUrl, getSpotifyUrl } from "../../helpers/Publications"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import BtnExternal from "../Buttons/BtnExternal"
import BtnRounded from "../Buttons/BtnRounded"
import Error404 from "../Errors/404"
import Icon from "../Icon/Icon"
import { MainContentLoading } from "../Layout/MainContent"
import PodcastEpisode from "../Podcasts/Episode"
import "./Detail.scss"

function PodcastEpisodeDetail({ slug }) {
  const {
    getPodcastSinglePublicationBySlug,
    getPodcastShowPublicationBySlug,
    setPageInformation,
  } = useGuestApi()
  const [podcastEpisodeItem, setPodcastEpisodeItem] = useState(null)
  const [showItem, setShowItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(null)

  useEffect(
    function fetchPublications() {
      getPodcastSinglePublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response))
        setPodcastEpisodeItem(response)
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slug]
  )

  useMemo(() => {
    if (podcastEpisodeItem) {
      const showSlug = podcastEpisodeItem?.podcast_show?.items[0]?.slug
      if (showSlug) {
        getPodcastShowPublicationBySlug(showSlug["nl-NL"]).then(response => {
          setLoading(false)
          setShowItem(response)
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastEpisodeItem])

  const regex = /(data-bt-embed=")(.*?)"/g
  const url = podcastEpisodeItem?.backtracks_embed?.body?.match(regex)

  useEffect(() => {
    setCurrentIndex(
      showItem?.podcasts
        .reverse()
        .indexOf(
          showItem?.podcasts.find(item => item.slug === podcastEpisodeItem.slug)
        )
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showItem])

  const prevSlug =
    currentIndex > 0 ? showItem?.podcasts[currentIndex - 1].slug : ""
  const nextSlug =
    currentIndex < showItem?.podcasts.length - 1
      ? showItem?.podcasts[currentIndex + 1].slug
      : ""
  const spotifyUrl = getSpotifyUrl(podcastEpisodeItem?.spotify_url)
  const appleUrl = getAppleUrl(podcastEpisodeItem?.apple_url)

  return (
    <>
      {loading && <MainContentLoading />}
      {podcastEpisodeItem && showItem && (
        <div className="pc-episode container">
          <div className="pc-episode__body">
            <div className="pc-episode__player">
              {url?.length > 0 && (
                <iframe
                  className="pc-episode__player-iframe"
                  src={
                    url[0].slice(15).slice(0, -1) +
                    "?embed=true&theme=dark&showDescription=false&showComments=false&showArtCover=true"
                  }
                />
              )}
            </div>
            <div className="pc-episode__info">
              <div className="pc-episode__buttons">
                <div className="pc-episode__buttons-prev-next">
                  <button
                    className="pc-episode__button pc-episode__button--prev"
                    disabled={currentIndex === 0}
                    onClick={() =>
                      navigate(
                        `/podcasts/${podcastEpisodeItem.podcast_show.items[0].slug["nl-BE"]}/${prevSlug}`
                      )
                    }
                  >
                    <Icon className="pc-episode__button-icon" name="chevron-left" />
                    <span>Vorige afl.</span>
                  </button>
                  <button
                    className="pc-episode__button pc-episode__button--next"
                    disabled={currentIndex === showItem.podcasts.length - 1}
                    onClick={() =>
                      navigate(
                        `/podcasts/${podcastEpisodeItem.podcast_show.items[0].slug["nl-BE"]}/${nextSlug}`
                      )
                    }
                  >
                    <span>Volgende afl.</span>
                    <Icon className="pc-episode__button-icon" name="chevron-right" />
                  </button>
                </div>
                <div className="pc-episode__buttons-links">
                  {spotifyUrl && (
                    <BtnExternal href={spotifyUrl} variant="spotify">
                      Naar <strong>Spotify</strong>
                    </BtnExternal>
                  )}
                  {appleUrl && (
                    <BtnExternal href={appleUrl} variant="apple-podcasts">
                      Naar <strong>Apple Podcasts</strong>
                    </BtnExternal>
                  )}
                </div>
              </div>
              <div className="pc-episode__info-text">
                {podcastEpisodeItem.layout.items.map((info, i) => (
                  <p key={`episode-info-${i}`}>{info.body}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="pc-episode__other-episodes">
            <ul className="pc-episode__other-episodes-list">
              {showItem &&
                showItem.podcasts
                  .filter(item => item.slug !== podcastEpisodeItem.slug)
                  .slice(0, 3)
                  .map((item, i) => {
                    return (
                      <li
                        className="pc-episode__other-episodes-item"
                        key={`show-${i}`}
                      >
                        <PodcastEpisode
                          {...item}
                          compact
                          count={showItem.podcasts.indexOf(item) + 1}
                          slug={`/podcasts/${podcastEpisodeItem.podcast_show.items[0].slug["nl-BE"]}/${item.slug}`}
                        />
                      </li>
                    )
                  })}
            </ul>
            {showItem && showItem.podcasts.length > 4 && (
              <div className="pc-episode__other-episodes-button">
                <BtnRounded
                  secondary
                  large
                  onClick={() =>
                    navigate(
                      `/podcasts/${podcastEpisodeItem.podcast_show.items[0].slug["nl-BE"]}`
                    )
                  }
                >
                  Alle afleveringen...
                </BtnRounded>
              </div>
            )}
          </div>
        </div>
      )}
      {!podcastEpisodeItem && !loading && <Error404 />}
    </>
  )
}

export default withGuestLayout(PodcastEpisodeDetail, {
  page: "Podcast-aflevering",
})
