import React from "react"
import { Link } from "gatsby"
import { getAppleUrl, getSpotifyUrl, getTitle } from "../../helpers/Publications"

import "./Episode.scss"
import BtnExternal from "../Buttons/BtnExternal"

const PodcastEpisode = props => {
  const spotifyUrl = getSpotifyUrl(props.spotify_url)
  const appleUrl = getAppleUrl(props.apple_url);
  return (
    <div className={`episode ${props.compact && "compact"}`}>
      <div className="episode__count">
        #{props.count < 10 ? `0${props.count}` : props.count}
      </div>
      <div className="episode__info">
        <h3 className="episode__name">{getTitle(props.title)}</h3>
        {props.layout?.items.map((item, i) => (
          <p key={`info-${i}`} className="episode__info-intro">
            {item.body}
          </p>
        ))}
      </div>
      {!props.compact && (
        <div className="episode__button">
          {spotifyUrl &&
            <div className="episode__button-provider">
              <BtnExternal href={spotifyUrl} variant="spotify">
                Naar <strong>Spotify</strong>
              </BtnExternal>
            </div>
          }
          {appleUrl &&
            <div className="episode__button-provider">
              <BtnExternal
                href={appleUrl}
                variant="apple-podcasts"
              >
                Naar <strong>Apple Podcasts</strong>
              </BtnExternal>
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default PodcastEpisode
