import React from "react"

import "./BtnExternal.scss"

const BtnExternal = ({ href, variant = "spotify", children }) => {
  const icons = {
    spotify: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0982 10.638C15.2301 8.34086 8.84979 8.12965 5.15723 9.25036C4.56427 9.43019 3.93721 9.09545 3.75752 8.5025C3.57783 7.90926 3.91228 7.28263 4.50566 7.10237C8.74447 5.81572 15.7908 6.0642 20.2439 8.70756C20.7774 9.02424 20.9523 9.71305 20.6361 10.2455C20.3197 10.7789 19.6303 10.9547 19.0982 10.638ZM18.9719 14.0407C18.7007 14.481 18.1249 14.619 17.6851 14.3488C14.4603 12.3664 9.54286 11.7923 5.72763 12.9504C5.23284 13.0998 4.71024 12.8208 4.56007 12.327C4.4109 11.8322 4.69004 11.3106 5.18383 11.1602C9.54228 9.83772 14.9603 10.4782 18.6641 12.7545C19.1039 13.0253 19.2423 13.6014 18.9719 14.0407ZM17.5032 17.3081C17.2877 17.6616 16.8272 17.7725 16.475 17.557C13.6569 15.8347 10.1101 15.4457 5.93305 16.3999C5.53054 16.4921 5.12946 16.2398 5.0376 15.8374C4.94532 15.4349 5.19666 15.0338 5.60004 14.942C10.1711 13.8969 14.0923 14.3467 17.2552 16.2795C17.6078 16.4949 17.7189 16.9556 17.5032 17.3081ZM12.0001 6.10352e-05C5.37269 6.10352e-05 0 5.37247 0 11.9998C0 18.6277 5.37269 23.9999 12.0001 23.9999C18.6276 23.9999 24 18.6277 24 11.9998C24 5.37247 18.6276 6.10352e-05 12.0001 6.10352e-05Z"
          fill="currentColor"
        />
      </svg>
    ),
    "apple-podcasts": (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 272 300"
        fill="currentColor"
      >
        <g
          transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1205 2990 c-493 -56 -919 -382 -1104 -845 -179 -446 -108 -955 187
-1340 109 -142 277 -284 427 -362 83 -42 202 -89 210 -82 9 10 -26 174 -42
191 -9 10 -50 36 -92 59 -298 160 -514 455 -586 799 -92 437 100 921 469 1182
477 336 1135 276 1529 -141 166 -176 279 -403 317 -634 15 -92 12 -294 -5
-384 -70 -370 -291 -669 -622 -844 l-88 -47 -17 -93 c-10 -51 -16 -95 -13 -97
6 -7 105 28 183 65 304 143 565 437 681 766 55 156 74 272 74 452 0 173 -14
275 -60 419 -143 457 -553 817 -1042 916 -116 23 -297 32 -406 20z"
          />
          <path
            d="M1220 2511 c-297 -44 -551 -232 -681 -506 -70 -149 -84 -212 -83
-385 1 -127 4 -156 27 -233 35 -112 85 -213 153 -304 54 -72 149 -159 205
-188 l29 -16 0 112 0 111 -54 60 c-107 117 -165 267 -173 443 -6 129 9 208 61
318 91 196 281 353 486 401 93 22 230 20 326 -4 322 -82 524 -330 541 -662 7
-129 -6 -206 -53 -306 -36 -78 -104 -181 -148 -221 -25 -24 -26 -29 -26 -138
l0 -114 31 18 c52 30 157 132 204 197 59 83 120 213 146 311 28 105 31 316 6
418 -82 329 -328 580 -651 662 -104 26 -261 38 -346 26z"
          />
          <path
            d="M1249 2043 c-141 -47 -244 -208 -225 -352 9 -67 60 -165 108 -205 72
-62 122 -80 218 -81 73 0 93 4 142 28 72 35 140 110 168 185 27 71 24 173 -7
244 -31 68 -98 136 -168 169 -70 34 -159 38 -236 12z"
          />
          <path
            d="M1254 1286 c-90 -29 -162 -96 -206 -192 -21 -45 -23 -61 -21 -219 4
-442 109 -778 267 -855 55 -26 87 -22 146 21 87 63 168 244 204 459 26 152 41
430 27 512 -28 171 -142 277 -304 284 -43 2 -89 -2 -113 -10z"
          />
        </g>
      </svg>
    ),
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={`btn-external ${variant}`}
    >
      {icons[variant]}
      <span>{children}</span>
    </a>
  )
}

export default BtnExternal
